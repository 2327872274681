import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { config } from '@/api/config-setting'
import { golfClass } from '@/api/golf-class'
import { members } from '@/api/members'
import { commonServices } from '@/api/common-services'
import 'devextreme/dist/css/dx.light.css'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
const {gzip, ungzip} = require('node-gzip');
import {
    required, min, max,
  } from '@validations'
  export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        Cleave,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRequiredRule,
        DxRangeRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
        Treeselect
    },
    data() {
        return {
            BookingOnlineShowTeeTime: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            tabIndex: null,
            inputMaskTime: {
              time: true,
              timePattern: ['h', 'm'],
            },
            BookingOnlineGolfClassDefault: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            BookingOnlineTaDefault: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            BookingOnlineTerm: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            ServiceAnnualFee: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            ServiceOnlineTerm: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            BookingOnlineSexCode: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            BookingOnlineNation: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            BookingOnlineMinGolfer: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            BookingOnlineGolferShowTeeTime: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            BookingOnlineMaxGolfer: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            BookingOnlineEmail: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            OptionAdvan: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            TimeDeleteBooking: {
              KeyValue: [],
              Id: null,
              LangId: null
            },
            listGolfClass: null,
            companies: null,
            listBarLocation: JSON.parse(localStorage.getItem('userGolfData')).BarLocation,
            hotelId: JSON.parse(localStorage.getItem('userData')).hotelId,
            ListService: [],
            listZoneSelect: [],
            ListServiceSelect: [],
            ListServiceConfigSelected: [],
            currentPage: 1,
            perPage: 20,
            dataGridRef: 'tablePackage',
            optionPerPage: [10, 20, 50],
            configTable: [
              {
                caption: 'Code',
                field: 'Code',
                isShow: true,
                alignment: 'center',
              },
              {
                caption: 'Tên dịch vụ',
                field: 'ServiceName',
                isShow: true,
                alignment: 'center',
              },
              {
                caption: '',
                field: 'action',
                isShow: true,
                cellTemplate: 'action',
                alignment: 'center',
              }
            ],
            listSex: [],
            currentLang: this.$i18n.locale,
            countries: [],
            listEmailBookingOnlineConfig: [],
            listPaymetOnlineTerm: [],
            listServiceOnlineTerm: [],
            listOptionAdvan: [],
            listLanguages: []
        }
    },
    watch: {
      tabIndex(value) {
        if(value == 0) {
          this.call_api_RCFV01('BOOKING_ONLINE_SHOW_TEETIME').then(res => {
            this.BookingOnlineShowTeeTime.KeyValue = JSON.parse(res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_ONLINE_SHOW_TEETIME").KeyValue)
            this.BookingOnlineShowTeeTime.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_ONLINE_SHOW_TEETIME").Id
            this.BookingOnlineShowTeeTime.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_ONLINE_SHOW_TEETIME").LangId
          })
          this.call_api_RCFV01('bo_config_golfclass_default').then(res => {
            this.BookingOnlineGolfClassDefault.KeyValue = Number(res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_golfclass_default").KeyValue)
            this.BookingOnlineGolfClassDefault.Id = res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_golfclass_default").Id
            this.BookingOnlineGolfClassDefault.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_golfclass_default").LangId
          })
          this.call_api_RCFV01('bo_config_ta_default').then(res => {
            this.BookingOnlineTaDefault.KeyValue = Number(res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_ta_default").KeyValue)
            this.BookingOnlineTaDefault.Id = res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_ta_default").Id
            this.BookingOnlineTaDefault.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_ta_default").LangId
          })
          this.call_api_RCFV01('RATE_ADVANTEGED_OPTIONS').then(res => {
            this.listOptionAdvan = res.Data.ConfigValue
          })
          this.call_api_RCFV01('BO_PAYMENT_ONLINE_TERM').then(res => {
            this.listPaymetOnlineTerm = res.Data.ConfigValue
            // this.BookingOnlineTerm.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BO_PAYMENT_ONLINE_TERM").Id
            // this.BookingOnlineTerm.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BO_PAYMENT_ONLINE_TERM").LangId
            // this.BookingOnlineTerm.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BO_PAYMENT_ONLINE_TERM").KeyValue
          })
          this.call_api_RCFV01('BO_SERVICE_TERM').then(res => {
            this.listServiceOnlineTerm = res.Data.ConfigValue
            // this.ServiceOnlineTerm.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BO_SERVICE_TERM").Id
            // this.ServiceOnlineTerm.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BO_SERVICE_TERM").LangId
            // this.ServiceOnlineTerm.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BO_SERVICE_TERM").KeyValue
          })
          this.call_api_RCFV01('BO_BOOKING_MIN_NUMBER_GOLFER').then(res => {
            this.BookingOnlineMinGolfer.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MIN_NUMBER_GOLFER").Id
            this.BookingOnlineMinGolfer.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MIN_NUMBER_GOLFER").LangId
            this.BookingOnlineMinGolfer.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MIN_NUMBER_GOLFER").KeyValue
          })
          this.call_api_RCFV01('BO_BOOKING_MAX_NUMBER_GOLFER').then(res => {
            this.BookingOnlineMaxGolfer.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MAX_NUMBER_GOLFER").Id
            this.BookingOnlineMaxGolfer.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MAX_NUMBER_GOLFER").LangId
            this.BookingOnlineMaxGolfer.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MAX_NUMBER_GOLFER").KeyValue
          })
          this.call_api_RCFV01('NUMBER_GOLFER_SHOW_TEETIME_IN_BOOKING').then(res => {
            this.BookingOnlineGolferShowTeeTime.Id = res.Data.ConfigValue.find(x => x.KeyCode == "NUMBER_GOLFER_SHOW_TEETIME_IN_BOOKING").Id
            this.BookingOnlineGolferShowTeeTime.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "NUMBER_GOLFER_SHOW_TEETIME_IN_BOOKING").LangId
            this.BookingOnlineGolferShowTeeTime.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "NUMBER_GOLFER_SHOW_TEETIME_IN_BOOKING").KeyValue
          })
          this.call_api_RCFV01('BO_CONFIG_NATIONNALITY_CODE').then(res => {
            this.BookingOnlineNation.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_NATIONNALITY_CODE").Id
            this.BookingOnlineNation.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_NATIONNALITY_CODE").LangId
            this.BookingOnlineNation.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_NATIONNALITY_CODE").KeyValue
          })
          this.call_api_RCFV01('BO_CONFIG_SEX_CODE').then(res => {
            this.BookingOnlineSexCode.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_SEX_CODE").Id
            this.BookingOnlineSexCode.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_SEX_CODE").LangId
            this.BookingOnlineSexCode.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_SEX_CODE").KeyValue
          })
          this.call_api_RCFV01('BOOKING_TIME_TO_DELETE_NOPAY_BOOKING_FROM_BO').then(res => {
            this.TimeDeleteBooking.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_TIME_TO_DELETE_NOPAY_BOOKING_FROM_BO").Id
            this.TimeDeleteBooking.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_TIME_TO_DELETE_NOPAY_BOOKING_FROM_BO").LangId
            this.TimeDeleteBooking.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_TIME_TO_DELETE_NOPAY_BOOKING_FROM_BO").KeyValue
          })
          this.call_api_RCFV01('BO_CONFIRM').then(res => {
            this.listEmailBookingOnlineConfig = res.Data.ConfigValue
            // this.BookingOnlineEmail.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIRM").Id
            // this.BookingOnlineEmail.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIRM").LangId
            // this.BookingOnlineEmail.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIRM").KeyValue
          })
        } else if (value == 1) {
          this.call_api_RCFV01('BOOKING_LIST_ANNUAL_FEE').then(res => {
            this.ServiceAnnualFee.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_LIST_ANNUAL_FEE").Id
            this.ServiceAnnualFee.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_LIST_ANNUAL_FEE").LangId
            this.ServiceAnnualFee.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_LIST_ANNUAL_FEE").KeyValue
            this.getServiceDetail()

          })
        }
      }
    },
    created() {
      this.getApiListGolfClass()
      this.getCompanies_RCL01()
      this.getListService()
      this.getListSex()
      this.getCountries_RNA01()
      this.getListLanguages()
    },
    methods: {
      async getCountries_RNA01() {
        const response = await members.getCountries({})
        if (response) {
          this.countries = response.Data.Country
        }
      },
        addTimeShift() {
            this.BookingOnlineShowTeeTime.KeyValue.push({
              ChannelCode: "BO",
              SaleDateFrom: null,
              SaleDateTo: null,
              TimeFrom: null,
              TimeTo: null
            })
          },
          async getListSex() {
            await commonServices
            .getCommon({ KeyGroup: 'SEX' })
            .then(res => {
              if (res) {
                this.listSex = res.Data.filter(x => x.LangId == this.currentLang)
              }
            })
          },
          async call_api_RCFV01(item) {
            const body = {
              KeyCode: item,
            }
            const response =  await config
              .API_RCFV01(body)
            return response
          },
          deleteTimeShift(index) {
            this.BookingOnlineShowTeeTime.KeyValue.splice(index,1)
          },
          async UpdateTimeSHift() {
            let check = true
            this.BookingOnlineShowTeeTime.KeyValue.forEach(x => {
              if(!x.SaleDateFrom || !x.SaleDateTo || !x.TimeFrom || !x.TimeTo) {
                check = false
              }
            })
            if(check) {
              const body = {
                ConfigValue: {
                  Id: this.BookingOnlineShowTeeTime.Id,
                  LangId: this.BookingOnlineShowTeeTime.LangId,
                  KeyValue: JSON.stringify(this.BookingOnlineShowTeeTime.KeyValue)
                },
              }
              await config
                .API_UCFV01(body)
                .then(res => {
                  this.showResToast(res)
                  this.call_api_RCFV01('BOOKING_ONLINE_SHOW_TEETIME').then(res => {
                    this.BookingOnlineShowTeeTime.KeyValue = JSON.parse(res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_ONLINE_SHOW_TEETIME").KeyValue)
                    this.BookingOnlineShowTeeTime.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_ONLINE_SHOW_TEETIME").Id
                    this.BookingOnlineShowTeeTime.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_ONLINE_SHOW_TEETIME").LangId
                  })
                })
            } else {
              this.showToast('error', 'Nhập đầy đủ thông tin')
            }
            
          },
          getApiListGolfClass() {
            golfClass
              .getListGolfClass(
                {
                  KeyWord: null,
                  CourseId: [],
                  Lang: this.$i18n.locale,
                }
              )
              .then(res => {
                if (res) {
                  this.listGolfClass = res.Data.GolfClass
                }
              })
          },
          async getCompanies_RCL01() {
            const response = await members.getCompanies()
            if (response) {
              this.companies = response.Data.Client
            }
          },
          async UpdateInfoBo() {
            await config.API_UCFV01({
              ConfigValue: {
                Id: this.BookingOnlineGolfClassDefault.Id,
                LangId: this.BookingOnlineGolfClassDefault.LangId,
                KeyValue: JSON.stringify(this.BookingOnlineGolfClassDefault.KeyValue)
              }
            }).then(res => {
              this.showResToast(res)
              this.call_api_RCFV01('bo_config_golfclass_default').then(res => {
                this.BookingOnlineGolfClassDefault.KeyValue = Number(res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_golfclass_default").KeyValue)
                this.BookingOnlineGolfClassDefault.Id = res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_golfclass_default").Id
                this.BookingOnlineGolfClassDefault.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_golfclass_default").LangId
              })
            })
            await config.API_UCFV01({
              ConfigValue: {
                Id: this.BookingOnlineTaDefault.Id,
                LangId: this.BookingOnlineTaDefault.LangId,
                KeyValue: this.BookingOnlineTaDefault.KeyValue ? JSON.stringify(this.BookingOnlineTaDefault.KeyValue) : ''
              }
            }).then(res => {
              this.showResToast(res)
              this.call_api_RCFV01('bo_config_ta_default').then(res => {
                this.BookingOnlineTaDefault.KeyValue = Number(res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_ta_default").KeyValue)
                this.BookingOnlineTaDefault.Id = res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_ta_default").Id
                this.BookingOnlineTaDefault.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "bo_config_ta_default").LangId
              })
            })
            await config.API_UCFV01({
              ConfigValue: {
                Id: this.BookingOnlineMinGolfer.Id,
                LangId: this.BookingOnlineMinGolfer.LangId,
                KeyValue: this.BookingOnlineMinGolfer.KeyValue
              }
            }).then(res => {
              this.showResToast(res)
              this.call_api_RCFV01('BO_BOOKING_MIN_NUMBER_GOLFER').then(res => {
                this.BookingOnlineMinGolfer.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MIN_NUMBER_GOLFER").KeyValue
                this.BookingOnlineMinGolfer.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MIN_NUMBER_GOLFER").Id
                this.BookingOnlineMinGolfer.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MIN_NUMBER_GOLFER").LangId
              })
            })
            await config.API_UCFV01({
              ConfigValue: {
                Id: this.BookingOnlineMaxGolfer.Id,
                LangId: this.BookingOnlineMaxGolfer.LangId,
                KeyValue: this.BookingOnlineMaxGolfer.KeyValue 
              }
            }).then(res => {
              this.showResToast(res)
              this.call_api_RCFV01('BO_BOOKING_MAX_NUMBER_GOLFER').then(res => {
                this.BookingOnlineMaxGolfer.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MAX_NUMBER_GOLFER").KeyValue
                this.BookingOnlineMaxGolfer.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MAX_NUMBER_GOLFER").Id
                this.BookingOnlineMaxGolfer.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BO_BOOKING_MAX_NUMBER_GOLFER").LangId
              })
            })
            await config.API_UCFV01({
              ConfigValue: {
                Id: this.BookingOnlineMaxGolfer.Id,
                LangId: this.BookingOnlineMaxGolfer.LangId,
                KeyValue: this.BookingOnlineMaxGolfer.KeyValue 
              }
            }).then(res => {
              this.showResToast(res)
              this.call_api_RCFV01('BOOKING_TIME_TO_DELETE_NOPAY_BOOKING_FROM_BO').then(res => {
                this.TimeDeleteBooking.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_TIME_TO_DELETE_NOPAY_BOOKING_FROM_BO").KeyValue
                this.TimeDeleteBooking.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_TIME_TO_DELETE_NOPAY_BOOKING_FROM_BO").Id
                this.TimeDeleteBooking.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_TIME_TO_DELETE_NOPAY_BOOKING_FROM_BO").LangId
              })
            })
            await config.API_UCFV01({
              ConfigValue: {
                Id: this.BookingOnlineGolferShowTeeTime.Id,
                LangId: this.BookingOnlineGolferShowTeeTime.LangId,
                KeyValue: this.BookingOnlineGolferShowTeeTime.KeyValue
              }
            }).then(res => {
              this.showResToast(res)
              this.call_api_RCFV01('NUMBER_GOLFER_SHOW_TEETIME_IN_BOOKING').then(res => {
                this.BookingOnlineGolferShowTeeTime.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "NUMBER_GOLFER_SHOW_TEETIME_IN_BOOKING").KeyValue
                this.BookingOnlineGolferShowTeeTime.Id = res.Data.ConfigValue.find(x => x.KeyCode == "NUMBER_GOLFER_SHOW_TEETIME_IN_BOOKING").Id
                this.BookingOnlineGolferShowTeeTime.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "NUMBER_GOLFER_SHOW_TEETIME_IN_BOOKING").LangId
              })
            })
            await config.API_UCFV01({
              ConfigValue: {
                Id: this.BookingOnlineSexCode.Id,
                LangId: this.BookingOnlineSexCode.LangId,
                KeyValue: this.BookingOnlineSexCode.KeyValue
              }
            }).then(res => {
              this.showResToast(res)
              this.call_api_RCFV01('BO_CONFIG_SEX_CODE').then(res => {
                this.BookingOnlineSexCode.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_SEX_CODE").KeyValue
                this.BookingOnlineSexCode.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_SEX_CODE").Id
                this.BookingOnlineSexCode.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_SEX_CODE").LangId
              })
            })
            await config.API_UCFV01({
              ConfigValue: {
                Id: this.BookingOnlineNation.Id,
                LangId: this.BookingOnlineNation.LangId,
                KeyValue: this.BookingOnlineNation.KeyValue 
              }
            }).then(res => {
              this.showResToast(res)
              this.call_api_RCFV01('BO_CONFIG_NATIONNALITY_CODE').then(res => {
                this.BookingOnlineNation.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_NATIONNALITY_CODE").KeyValue
                this.BookingOnlineNation.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_NATIONNALITY_CODE").Id
                this.BookingOnlineNation.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BO_CONFIG_NATIONNALITY_CODE").LangId
              })
            })
          },
          async UpdateBoTerm() {
            this.listPaymetOnlineTerm.forEach(async (x,index) => {
              const body = {
                ConfigValue: {
                  Id: x.Id,
                  LangId: x.LangId,
                  KeyValue: x.KeyValue
                },
                Zipped: true
              }
              await config.API_UCFV01(body)
              if(index == this.listPaymetOnlineTerm.length - 1) {
                this.call_api_RCFV01('BO_PAYMENT_ONLINE_TERM').then(res => {
                  this.listPaymetOnlineTerm = res.Data.ConfigValue
                })
                this.showToast('success', this.$t('golf_common_success'))
              }
            }) 
          },
          async UpdateServiceTerm() {
            this.listServiceOnlineTerm.forEach(async (x,index) => {
              const body = {
                ConfigValue: {
                  Id: x.Id,
                  LangId: x.LangId,
                  KeyValue: x.KeyValue
                },
                Zipped: true
              }
              await config.API_UCFV01(body)
              if(index == this.listServiceOnlineTerm.length - 1) {
                this.call_api_RCFV01('BO_SERVICE_TERM').then(res => {
                  this.listServiceOnlineTerm = res.Data.ConfigValue
                })
                this.showToast('success', this.$t('golf_common_success'))
              }
            }) 
          },
          async UpdateOptionAdv() {
            this.listOptionAdvan.forEach(async (x,index) => {
              const body = {
                ConfigValue: {
                  Id: x.Id,
                  LangId: x.LangId,
                  KeyValue: x.KeyValue
                },
                Zipped: false
              }
              await config.API_UCFV01(body)
              if(index == this.listOptionAdvan.length - 1) {
                this.call_api_RCFV01('RATE_ADVANTEGED_OPTIONS').then(res => {
                  this.listOptionAdvan = res.Data.ConfigValue
                })
                this.showToast('success', this.$t('golf_common_success'))
              }
            }) 
          },
          async UpdateBookingEmail() {
            this.listEmailBookingOnlineConfig.forEach(async (x,index) => {
              const body = {
                ConfigValue: {
                  Id: x.Id,
                  LangId: x.LangId,
                  KeyValue: x.KeyValue
                },
                Zipped: true
              }
              await config.API_UCFV01(body)
              if(index == this.listEmailBookingOnlineConfig.length - 1) {
                this.call_api_RCFV01('BO_CONFIRM').then(res => {
                  this.listEmailBookingOnlineConfig = res.Data.ConfigValue
                })
                this.showToast('success', this.$t('golf_common_success'))
              }
            }) 
          },
          async getListService() {
            const body = {
              HotelId: this.hotelId,
              ZoneId: this.listZoneSelect.map(x => String(x)),
              BookingType: 'RETAIL',
            }
            await commonServices
              .getListService(body)
              .then(res => {
                if (res.Status === '200') {
                  this.ListServiceSelect = []
                  this.ListService = []
                 res.Data.Menu.forEach(x => {
                  x.MenuDetail.forEach(y => {
                    if(!this.ListService.find(z => z.ProductId == y.ProductId)) {
                      y.id = y.ProductId
                      y.label = y.ProductName
                      this.ListService.push(y)
                    }
                  })
                 })
                }
              })
          },
          async updateServiceAnnual() {
            if (this.ListServiceSelect.length > 0) {
              const data = [...this.ServiceAnnualFee.KeyValue.split(','), ...this.ListServiceSelect]
              const dataServiceId = []
              data.forEach(x => {
                if(!dataServiceId.find(y => y == x)) {
                  dataServiceId.push(String(x))
                }
              })
              this.call_api_updateServiceAnnual(dataServiceId.join(','))
            } else {
              this.showToast('error', 'Vui lòng chọn dịch vụ')
            }
          },
          async call_api_updateServiceAnnual(data) {
            const body = {
              ConfigValue: {
                Id: this.ServiceAnnualFee.Id,
                LangId: this.ServiceAnnualFee.LangId,
                KeyValue: data
              },
              Zipped: false
            }
            await config.API_UCFV01(body).then(res => {
              if(res.Status == 200) {
                this.showResToast(res)
                this.call_api_RCFV01('BOOKING_LIST_ANNUAL_FEE').then(res => {
                  this.ServiceAnnualFee.Id = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_LIST_ANNUAL_FEE").Id
                  this.ServiceAnnualFee.LangId = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_LIST_ANNUAL_FEE").LangId
                  this.ServiceAnnualFee.KeyValue = res.Data.ConfigValue.find(x => x.KeyCode == "BOOKING_LIST_ANNUAL_FEE").KeyValue
                  this.getServiceDetail()
                })
                this.listZoneSelect = []
                this.ListServiceSelect = []
                this.ListService = []
              }
            })
          },
          async getServiceDetail() {
            const body = {
              Service: []
            }
            this.ServiceAnnualFee.KeyValue.split(',').forEach(x => {
              body.Service.push({
                Id: x,
                ServiceType:"SERVICE"
              })
            })
            await commonServices.api_RSVAR(body).then(res => {
              this.ListServiceConfigSelected = res.Data.Service
            })
          },
          deleteService(item) {
            const data = this.ServiceAnnualFee.KeyValue.split(',').filter(x => x != item.data.Id)
            this.call_api_updateServiceAnnual(data.join(','))
          },
          async getListLanguages() {
            await commonServices.getLanguages()
                .then(res => {
                    if (res) {
                        this.listLanguages = res.Data.LangCategory.filter(x => x.Active == true)
                    }
                })
        },
    },
}